
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Loader from '@/components/utilities/Loader.vue';
import Stepper from '@/components/utilities/Stepper.vue';
import InfoSide from '@/components/InfoSide.vue';
import FirstStep from '@/modules/verificationLevels/components/levelTwo/FirstStep.vue';
import SecondStep from '@/modules/verificationLevels/components/levelTwo/SecondStep.vue'
import { auth, verifLevels,notifications } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import VerifLevelMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import NotifMethods from '@/store/notifications/methods/verificationLevels.methods'
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
import { User } from '@/modules/auth/interfaces/user.interface';
import { VerifLevelReq } from '../interfaces/requirement.interface';
import { showToast } from '@/utils/global-functions';
import { ReqLevelTwo, RequestStatus } from '../interfaces/requests.interface';

@Component({
    components: {
        Loader,
        Stepper,
        InfoSide,
        FirstStep,
        SecondStep
        
    }
})
export default class LevelTwo extends Vue {

    steps= [
        { component: 'SecondStep', label: '' },
        // { component: 'FirstStep', label: '' },
    ]
    currentComponent = this.steps[0].component
    currentStep = 1;
    $refs : any ={}
    loader = true;
    loading = true;

    async mounted(){
        try{
            this.loading = true;
            if (await this.fetchVerifLevelReqs(this.userData.email_user)){
                this.levelTwoRequest.email_user.value = this.userData.email_user
                if (this.verifLevelReqs.level_one === null){
                    showToast('Usuario no autorizado para aumento de nivel', 'error');
                    // this.$router.push({name: 'VerificationLevelsFeatures'}).catch();
                } 
                else if (this.levelTwoStatus === RequestStatus.UNDER_REVIEW){
                    showToast('Su solicitud de nivel intemedio se encuentra en revisión', 'error');
                    this.$router.push({name: 'VerificationLevelsFeatures'}).catch();
                }
                // else if (this.levelTwoStatus === RequestStatus.APPROVED){
                //     showToast('Usted ya ha solicitado la verificación avanzada','error')
                //     this.$router.push({name: 'VerificationLevelsFeatures'}).catch();
                // } 
                this.loader = false;
                this.loading = false;
            }
            else {
                throw new Error;
            }
        }catch(e){
            this.$router.push({name: 'ErrorPage'}).catch()
        }
    }


    getRequestStatus(request: any){
        this.levelTwoRequest = request;
    }
   
    nextStep(){
        this.$refs.step.moveStep(this.currentStep+1);
        this.currentStep += 1;
        this.currentComponent= this.steps[this.currentStep-1].component;
    }

    prevStep(){
        this.$refs.step.moveStep(this.currentStep-1);
        this.currentStep -= 1;
        this.currentComponent= this.steps[this.currentStep-1].component;
    }

    get isRejected() {
        return this.levelTwoStatus === RequestStatus.REJECTED
    }

    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
        userData!: User;
    /* VERIF LEVELS */
    @verifLevels.Action(VerifLevelMethods.actions.FETCH_VERIF_LEVEL_REQUIREMENTS)
        fetchVerifLevelReqs!: (emailUser: string) => Promise<boolean>
    @verifLevels.Getter(VerifLevelMethods.getters.GET_VERIF_LEVEL_REQUIREMENTS)
        verifLevelReqs!: {level_one: VerifLevelReq[], level_two: VerifLevelReq[], email_user: string}
    @verifLevels.Getter(VerifLevelMethods.getters.GET_LEVEL_TWO_REQUEST)
        levelTwoRequest!: ReqLevelTwo
    @verifLevels.Getter(VerifLevelMethods.getters.GET_LEVEL_TWO_REQUEST_STATUS)
        levelTwoStatus!: RequestStatus
    /* NOTIFICATIONS */
    @notifications.Getter(NotifMethods.getters.GET_MARQUEE_NOTIFICATIONS)
        marqueeNotifications!: Notif[];
}
