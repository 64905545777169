
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import Toast from '@/components/utilities/Toast.vue';
import InputFile from '@/components/utilities/InputFile.vue';
import { availableImagesTypes } from '@/utils/constants';
import { required, maxLength } from 'vuelidate/lib/validators';
import { showToast } from '@/utils/global-functions';
import { _arrayBufferToBase64 } from '@/utils/image-functions';
import { ReqLevelTwo } from '../../interfaces/requests.interface';
import SvgIcon from '@/components/utilities/SvgIcon.vue'
@Component({
    components: {
        Toast,
        InputFile,
        SvgIcon
    },
    mixins: [validationMixin],
    validations:{
       localRequirements:{
           funds_source:{
                value:{
                    required,
                    maxLength: maxLength(100),
                }
           },
           residency_proof:{
               value: {required}
           }
       },
    }
})
export default class FirstStep extends Vue {
    @Prop() requirements!: ReqLevelTwo; //tipar
    loader = true;
    fundsSource : string = ''
    formats: Array<string> = [];
    localRequirements : ReqLevelTwo; // tipar
    imageAlreadyExists = false;

    mounted(){
        this.formats = availableImagesTypes;  
        this.localRequirements = this.requirements;
        if (this.requirements.residency_proof.rejected === false)
            this.imageAlreadyExists = true
        else 
            this.requirements.residency_proof.value = ''
        this.formats = availableImagesTypes;
        this.loader = false;
    }

    getImage(image: string){
        this.localRequirements.residency_proof.value = image;
    }

    submitForm(){
        this.$v.$touch();
        if (this.$v.localRequirements.residency_proof.value.$invalid && this.localRequirements.residency_proof.rejected) this.showImageError();
        else if (!this.$v.$invalid){
                this.$emit('sendRequestStatus', this.localRequirements)
                this.$emit('nextStep');
        }
    }

    getImageSrc(imageContent: any){
        if (imageContent.data)
            return _arrayBufferToBase64(imageContent.data);
        return _arrayBufferToBase64(imageContent);
    }

    showImageError(){
        showToast('Debe anexar una imagen de su comprobante de residencia ','error')
    }

}
